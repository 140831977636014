@font-face {
  font-family: 'HyundaiText';
  src: local('HyundaiSansTextKRRegular'), url('./assets/fonts/Hyundai/HyundaiSansTextKRRegular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'HyundaiHeading';
  src: local('HyundaiSansHeadKRMedium'), url('./assets/fonts/Hyundai/HyundaiSansHeadKRMedium.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'HyundaiHeadRegular';
  src: local('HyundaiSansHeadKRMedium'), url('./assets/fonts/Hyundai/HyundaiSansHeadKRRegular.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'HyundaiText';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
strong {
  font-family: 'HyundaiHeading';
}

.font-1 {
  font-size: 18px;
}

.otpContainer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.hyundaiText {
  font-family: 'HyundaiText';
}

.hyundaiTextHead {
  font-family: 'HyundaiHeading';
}

.hyundaiHeadRegular {
  font-family: 'HyundaiHeadRegular';
}

.otpInput {
  width: 48px !important;
  height: 48px;
  font-size: 16px;
  font-family: 'HyundaiHeading';
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #f6f3f2;
}

.loading{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  text-align: center;
  color: #fff;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  margin: 8px;
  border: 6px solid #00AAD2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00AAD2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.passwordStrengthBar > div{
  border-radius: 10px;
  overflow: hidden;
}
.passwordStrengthBar > div > div {
  height: 6px !important;
}

.passwordStrengthBar > div > div:nth-child(even) {
  width: 0 !important;
}


.line{
  width: 100%;
  height: 1px;
  background-color: #E1DFDF;
  position: relative;
}
button:disabled{
  background-color: #ccc !important;
}
.DatePicker{
  z-index: 1 !important;
}
.DatePicker__input{
  text-align: left !important;
  font-size: 16px !important;
  padding: 12px 16px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
